const ROUTERS = {
  BLOG: 'https://blog.earkick.com',
  ABOUT: '/about',
  CHAT: '/chat',
  //CHAT_PANDA_SELECTION: '/chat/start',
  CHAT_PANDA_HELLO: '/chat/start',
  DOWNLOAD: '/download',
  HOME: '/',
  JOBS: '/jobs',
  PRIVACYPOLICY: '/privacy-policy',
  PRIVACY: '/privacy',
  REFERRAL: '/r',
  PANDA_PERSONAS_SHARE: '/panda-personas',
  SHARE_NEW: '/share/:folderId',
  SHARE: '/share',
  TEAM_JOIN: '/team/join',
  TECHNOLOGIES: '/technologies',
  TERMS: '/terms',
  TERMSOFSERVICE: '/terms-of-service',
  TRY_NOW: '/try-now',
  TRY_NOW_DEMO: '/try-now/demo',
  TRY_NOW_DEMO_RESULTS: '/try-now/demo/results/:resultId',
  WEB_ONBOARDING: '/web-onboarding',
};

export default ROUTERS;
